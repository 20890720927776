<template>
  <div class="bookstore">
    <BannerStore></BannerStore>
    <Store></Store>
  </div>
</template>

<script>
  import Store from "@/components/index/Store.vue";
  import BannerStore from "@/components/banner/BannerStore.vue";
  export default {
    name:'BookStore',
    components: {
      BannerStore,
      Store
    }
  }
</script>

<style>
</style>
