<template>
  <div class="banner-container">
    <img src="@/assets/images/banner/banner-store-bg.png" alt="banner">
    <div class="container-of">
      <button class="btn btn-store" type="button" @click="goStore">去书店看看</button>
    </div>
  </div>
</template>

<script>
  import storeLink from "@/utils/storelink.js"
  export default {
    name: 'BannerStore',
    methods: {
      goStore() {
        storeLink.goStore()
      }
    }
  }
</script>

<style>
  .container-of {
    text-align: center;
  }

  .btn-store {
    margin-top: 15%;
    width: 25%;
    min-width: 200px;
  }
</style>
